<template>
  <div class="grid">
    <div class="col-12 md:col-6" v-if="selectedStore.id">
      <div class="card">
        <h5>Error Logs</h5>
        <br />

        <ul class="layout-topbar-menu hidden lg:flex origin-top">
          <li v-if="selectedStore">
            <span class="customer-badge status-qualified" data-v-61bc1bb6="">Selected Store</span>
            :
            <Dropdown v-model="selectedStore" :options="allStores" optionLabel="shop" placeholder="Select Store"
              :filter="true" @change="handleStoreChange" />
          </li>
        </ul>
        <br />
        <div class="col-12">
          <div class="card">
            <h5>Error Logs</h5>
            <DataTable :value="this.errorLogs" :rows="25" :paginator="true" responsiveLayout="scroll"
              :sortOrder="-1" sortField="id">
              <Column field="calculatorUniqueIdentifier" header="Calculator" style="width: 45%" class="wid1"></Column>
              <Column field="message" header="Message" :style="{ width: '80%' }">
                <template #body="{ data }">
                  <span style="margin-left: 0.5em; vertical-align: middle" class="image-text">$ {{ data.message }}</span>
                </template>
              </Column>

              <Column field="label" header="Label" :style="{ width: '200px' }">
              </Column>
              <Column field="insertDate" header="Insert Date" :style="{ width: '200px' }">
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiStore from '../store/index';
export default {
  computed: {
    allStores() {
			return apiStore.getters['storeinternal/getAllStores'];
		}
	},
  watch: {
    'selectedStore': function(newVal, oldVal) {
      this.loadStoreErrorLogs();
    }
  },
  data() {
    return {
      selectedStore: {},
      stores: [],
      errorLogs: []
    };
  },
  interval: null,
  async mounted() {
    await this.resolveCurrentStoreSelection();
    await this.loadStoreErrorLogs();
  },
  methods: {
    async resolveCurrentStoreSelection() {
      this.selectedStore = await apiStore.getters["storeinternal/getSelectedStore"]
    },
    async loadStoreErrorLogs() {
      var installationId = this.selectedStore.id;
      var response = await apiStore.dispatch("error/getStoreErrorLogs", { id: installationId});
      this.errorLogs = response;
    }
  },
};
</script>